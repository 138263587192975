import { Loader } from '@120wateraudit/envirio-components'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  CommunicationType,
  TriggerType,
  CommunicationStatus
} from '@120wateraudit/communications-types'
import { useQuery } from '@apollo/client'

import { pwsClient } from 'src/apollo-clients'
import { ReactComponent as CheckIcon } from 'src/assets/images/Check.svg'
import { ReactComponent as LetterIcon } from 'src/assets/images/Letter.svg'
import { useFeatures } from 'src/hooks'
import { useToggle } from 'src/hooks/useToggle'
import {
  ButtonsWrapper,
  ErrorMessage,
  ModalContent,
  ModalHeading,
  Wrapper
} from 'src/modules/Modal/shared'
import { ModalWrapper } from 'src/modules/Samples/modals/CompleteSamplesModal/shared'
import SendModalContent from './SendModalContent'
import SendModalFooter from './SendModalFooter'
import { SupportLink } from 'src/components/SupportLink'
import { NoTemplatesWarning } from 'src/components/Communications/NoTemplatesWarning'
import { Link } from 'react-router-dom'
import {
  AccountSoftwarePackage,
  GET_PACKAGE_NAME_FOR_ACCOUNT_QUERY
} from 'src/containers/Communications/Dashboard/dataAccess'
import { SoftwarePackageName } from 'src/types/enums/SoftwarePackageName'
import {
  useGetAllCommunicationsQuery,
  useCreateCommunicationBatchMutation,
  useGetCommunicationQuery
} from 'src/services/communications-two-api'
import { Location } from 'src/types/Location'
import { CommunicationV2 } from 'src/types/CommunicationsV2'
import { toastSuccess, toastError } from 'src/utils/toast'
import PDFModalContainerV2 from "src/containers/Locations/SendCommunicationButton/PDFModalContainerV2";
import {skipToken} from "@reduxjs/toolkit/query";
export type CommunicationOption = Pick<
  CommunicationV2,
  'id' | 'name' | 'templateId'
>

interface CondensedLocations {
  id: number
  addressLine1: string
  city: string
  state: string
  zip: string
  contactFirstName: string
  contactLastName: string
}

const SendCommunicationModalTwo: React.FC<Props> = ({
  accountId,
  hideInventoryChoices,
  locationIds: baseLocationIds,
  locations,
  onClose,
  onCompletion,
  programId,
  eventId
}) => {
  const { data: commsData, isLoading: isLoadingComms } =
    useGetAllCommunicationsQuery({
      hideInventoryChoices,
      searchParams: {
        activeFilters: {
          'communications.communicationType': 'Letter',
          'communications.status': CommunicationStatus.Active,
          'communications.triggerType': TriggerType.AdHocLocation
        },
        page: 1,
        pageSize: 10000
      }
    })

  const communications = commsData?.data ?? []

  const [
    createCommunicationBatch,
    { isLoading: isCommunicationPostLoading, error }
  ] = useCreateCommunicationBatchMutation()

  const [selectedCommunication, setSelectedCommunication] = useState<
      undefined | CommunicationV2
  >(undefined)

  const { data: getCommunicationData , isLoading: isLoadingCommunication } 
      = useGetCommunicationQuery(selectedCommunication && selectedCommunication.id ? { communicationId: selectedCommunication.id } : skipToken)

  const { accountHasCommsAutomatedMailingFeature } = useFeatures()
  const [openPreviewContainer, togglePreviewContainer] = useToggle(false)
  const [isCertifiedMail, toggleIsCertifiedMail] = useToggle(false)
  const [selectedCommunicationType, setSelectedCommunicationType] = useState<
    undefined | CommunicationTypes
  >(undefined)
  const [stepNumber, setStepNumber] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [zIndex, setZIndex] = useState<number>(1000)
  const [errorMessage, setErrorMessage] = useState<undefined | string>()

  const condensedLocations: CondensedLocations[] = useMemo(
    () =>
      locations.map(
        ({
          id,
          addressLine1,
          city,
          state,
          zip,
          contactFirstName,
          contactLastName
        }) => ({
          id,
          addressLine1,
          city,
          state,
          zip,
          contactFirstName: contactFirstName as string,
          contactLastName: contactLastName as string
        })
      ),
    [locations]
  )

  const { data: accountSoftwarePackage } = useQuery<AccountSoftwarePackage>(
    GET_PACKAGE_NAME_FOR_ACCOUNT_QUERY,
    {
      client: pwsClient,
      variables: { accountId: accountId },
      skip: !accountId
    }
  )

  const clearState = useCallback(() => {
    setSelectedCommunication(undefined)
    setSelectedCommunicationType(undefined)
    setStepNumber(0)
  }, [])

  const onNextClicked = useCallback(() => {
    if (stepNumber !== 2 && selectedCommunication) {
      setStepNumber(stepNumber + 1)
    }
  }, [stepNumber, selectedCommunication, selectedCommunicationType])

  const onBackClicked = useCallback(() => {
    if (stepNumber === 1) {
      setStepNumber(0)
    }
  }, [stepNumber])

  const onSend = useCallback(async () => {
    if (selectedCommunication !== undefined) {
      setIsLoading(true)

      try {
        createCommunicationBatch &&
          (await createCommunicationBatch({
            communicationBatch: {
              communicationId: selectedCommunication.id,
              locations: condensedLocations,
              isCertifiedMail,
              programId,
              eventId
            }
          }).unwrap())

        onClose()
        toastSuccess('Success!')
      } catch {
        toastError('An error occurred, please try again.')
      }
    }
  }, [
    accountId,
    isCertifiedMail,
    onCompletion,
    onNextClicked,
    selectedCommunication
  ])

  const onCloseWindow = useCallback(() => {
    clearState()
    onClose()
  }, [clearState, onClose])

  const closePreview = useCallback(() => {
    setZIndex(1000)
    togglePreviewContainer()
  }, [togglePreviewContainer])

  const fetchCommunicationData = useCallback(async () => {
    if (selectedCommunication?.id) {
      togglePreviewContainer()
    }
  }, [
    accountId,
    selectedCommunication,
    togglePreviewContainer
  ])

  const onDownload = useCallback(async () => {
    if (selectedCommunication !== undefined) {
      try {
        createCommunicationBatch &&
          (await createCommunicationBatch({
            communicationBatch: {
              communicationId: selectedCommunication.id,
              locations: condensedLocations,
              isDownload: true,
              programId,
              eventId
            }
          }).unwrap())
        onClose()
        toastSuccess('Success!')
      } catch {
        toastError('An error occurred, please try again.')
      }
    }
  }, [accountId, onNextClicked, selectedCommunication, baseLocationIds])

  const onCommunicationSelected = useCallback(
    ({ value }: { value: string }) => {
      const selected = communications.find(c => c.id === value)
      setSelectedCommunication(selected)
    },
    [communications]
  )

  const onCommunicationTypeSelected = useCallback(
    ({ value }: { value: CommunicationTypes }) => {
      setSelectedCommunicationType(CommunicationTypes[value])
    },
    []
  )

  const isPlural = baseLocationIds.length > 1
  const renderHeading = useCallback(() => {
    if (selectedCommunicationType === CommunicationTypes['Letter as PDF']) {
      if (stepNumber === 2) {
        return 'Completed!'
      }

      if (stepNumber === 1) {
        return `Print Communication${isPlural ? 's' : ''}`
      }
    }

    if (stepNumber === 1 && selectedCommunicationType === 'Letter') {
      return `Send Letters`
    }

    if (stepNumber === 2) {
      return 'Completed!'
    }

    return `Send Communication${isPlural ? 's' : ''}`
  }, [isPlural, stepNumber, selectedCommunicationType])

  const renderIcon = useCallback(() => {
    if (stepNumber === 2) {
      return CheckIcon
    }

    return LetterIcon
  }, [stepNumber, selectedCommunicationType])

  if (
    !accountHasCommsAutomatedMailingFeature &&
    accountSoftwarePackage?.accountSoftwarePackageName ===
      SoftwarePackageName.PwsPortal
  ) {
    return (
      <ModalWrapper style={{ gap: '0' }}>
        <ModalHeading>
          <h4 style={{ fontSize: '2.25rem' }}>{renderHeading()}</h4>
        </ModalHeading>
        <StyledHeader style={{ marginTop: '0px' }}>
          This feature is not included in the PWS Portal account procured on
          your behalf.
          <br />
          <br />
          If you would like to learn more about this feature, please reach out
          to{' '}
          <EmailLink href="mailto:support@120water.com">
            support@120water.com
          </EmailLink>
          . Communication templates can be downloaded in the{' '}
          <Link onClick={onClose} to={`/administration/resources`}>
            Resources
          </Link>{' '}
          tab on the Account Settings page.
        </StyledHeader>
      </ModalWrapper>
    )
  }

  if (!accountHasCommsAutomatedMailingFeature) {
    return (
      <ModalWrapper>
        <ModalHeading>
          <h5>{renderHeading()}</h5>
        </ModalHeading>
        <StyledHeader>
          Want to use this feature?{' '}
          <SupportLink>Reach out to support</SupportLink> to upgrade your
          package!
        </StyledHeader>
      </ModalWrapper>
    )
  }

  if (isLoadingComms ?? isCommunicationPostLoading) {
    return <Loader />
  }

  if (communications && communications.length < 1) {
    return <NoTemplatesWarning onClose={onCloseWindow} />
  }

  return (
    <ModalWrapper>
      <Wrapper
          style={{
            overlay: {
              zIndex: `${zIndex}`
            }
          }}>
        <ModalHeading>
          <Icon as={renderIcon()} />
          <h5>{renderHeading()}</h5>
        </ModalHeading>
        <React.Suspense fallback={<Loader />}>
          {(!isLoadingComms && (
            <>
              <ModalContent>
                <SendModalContent
                  communications={communications}
                  fetchCommunicationData={fetchCommunicationData}
                  hasAutomatedLetterMailingFeature={
                    !!accountHasCommsAutomatedMailingFeature
                  }
                  isCertifiedMail={isCertifiedMail}
                  isPlural={isPlural}
                  locationIds={baseLocationIds}
                  onCloseWindow={onCloseWindow}
                  onCommunicationSelected={onCommunicationSelected}
                  onCommunicationTypeSelected={onCommunicationTypeSelected}
                  selectedCommunication={selectedCommunication}
                  selectedCommunicationType={selectedCommunicationType}
                  stepNumber={stepNumber}
                  toggleIsCertifiedMail={toggleIsCertifiedMail}
                />
              </ModalContent>
              {(error || errorMessage) && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <ErrorMessage style={{ fontSize: '14px' }}>
                    Error
                  </ErrorMessage>
                  <div
                    style={{
                      marginBottom: '15px',
                      fontSize: '14px'
                    }}>
                    If sending letters please do a quick check on the{' '}
                    <Link
                      onClick={onCloseWindow}
                      to={'/communications/letter-logs'}>
                      Letter Log
                    </Link>{' '}
                    page to make sure no letters were sent out.
                  </div>
                </div>
              )}
              <ButtonsWrapper>
                <SendModalFooter
                  disabledForError={!!error}
                  isSaving={isLoading}
                  onBack={onBackClicked}
                  onClose={onClose}
                  onDownload={onDownload}
                  onNext={onNextClicked}
                  onSend={onSend}
                  selectedCommunication={selectedCommunication}
                  selectedCommunicationType={selectedCommunicationType}
                  stepNumber={stepNumber}
                />
              </ButtonsWrapper>
              {openPreviewContainer &&
                  !isLoadingComms && !isLoadingCommunication &&
                  getCommunicationData && (
                      <PDFModalContainerV2
                          accountId={accountId}
                          closePreview={closePreview}
                          data={getCommunicationData}
                          isOpen={openPreviewContainer}
                          setErrorMessage={setErrorMessage}
                          setZIndex={setZIndex}
                          toggle={togglePreviewContainer}
                      />
              )}
            </>
          )) || <Loader />}
        </React.Suspense>
      </Wrapper>
    </ModalWrapper>
  )
}

interface Props {
  accountId: number
  locationIds: number[]
  locations: Location[]
  hideInventoryChoices?: boolean
  onClose: () => void
  onCompletion: () => void
  programId?: number
  eventId?: number
}

enum PDFSelection {
  'Letter as PDF' = 'Letter as PDF'
}

const CommunicationTypes = Object.assign({}, CommunicationType, PDFSelection)

// eslint-disable-next-line @typescript-eslint/no-redeclare
type CommunicationTypes = keyof typeof CommunicationTypes

const Icon = styled.img`
  width: 55px;
  height: 55px;
`
const StyledHeader = styled.h4`
  font-weight: 500;
`

const EmailLink = styled.a`
  text-decoration: none;
`

export default React.memo(SendCommunicationModalTwo)
